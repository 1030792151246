import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_MEETINGS: (state, data) => new Promise((resolve, reject) => {
      Vue.prototype.$http.get(`/api/satisfactions?token=${data.token}`)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    CREATE_SATISFACTION_SURVEY: (state, { token, data }) => new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`/api/satisfactions?token=${token}`, data)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
  },
}
