import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import LogRocket from 'logrocket'
import createLogRocketPlugin from 'logrocket-vuex'

// Modules
import app from './app'
import filters from './app/filters'
import autofill from './app/autofill'
import clipboards from './app/clipboards'
import campaign from './app/campaign'
import campaignLink from './app/campaign-link'
import importations from './app/importations'
import notifications from './app/notifications'
import prospects from './app/prospects'
import followUpProspects from './app/prospects/followUpProspects'
import otherRenews from './app/other-renews'
import prospectContacts from './app/prospect-contacts'
import communicationHistory from './app/communication-history'
import worksheet from './app/worksheet'
import lock from './app/worksheet/lock'
import meetings from './app/worksheet/meetings'
import worksheetLogs from './app/worksheet/logs'
import statistics from './app/statistics'
import users from './app/users'
import punch from './app/punch'
import tickets from './app/tickets'
import supportTickets from './app/support-tickets'
import files from './app/files'
import employeeSchedule from './app/employee-schedule'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import bonus from './app/bonus'
import agentBonus from './app/agent-bonus'
import projects from './app/projects'
import clientPortal from './app/client-portal'
import rsvp from './app/rsvp'
import emailQuickActions from './app/email-quick-actions'
import invoices from './app/invoices'
import campaignsStatistics from './app/campaigns-statistics'
import satisfactionSurvey from './app/satisfaction-survey'
import locations from './app/locations'
import contacts from './app/contacts'
import clientProfile from './app/client-profile'
import dailyTasks from './app/daily-tasks'

const dontStoreMutations = ['someMutationThatGetsCastoreProspectlledTooOften']

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that prevent storing in the localstorage
  filter: mutation => dontStoreMutations.indexOf(mutation.type) === -1, // Boolean
})

Vue.use(Vuex)

if (process.env.NODE_ENV === 'production') {
  LogRocket.init(process.env.VUE_APP_LOGROCKET_KEY, {
    console: {
      shouldAggregateConsoleErrors: false,
    },
  })
}
const logrocketPlugin = createLogRocketPlugin(LogRocket)

export default new Vuex.Store({
  modules: {
    app,
    filters,
    autofill,
    clipboards,
    campaign,
    campaignLink,
    importations,
    notifications,
    prospects,
    followUpProspects,
    otherRenews,
    prospectContacts,
    communicationHistory,
    worksheet,
    lock,
    meetings,
    worksheetLogs,
    statistics,
    users,
    punch,
    tickets,
    supportTickets,
    files,
    employeeSchedule,
    appConfig,
    verticalMenu,
    agentBonus,
    bonus,
    projects,
    clientPortal,
    rsvp,
    emailQuickActions,
    invoices,
    campaignsStatistics,
    locations,
    contacts,
    clientProfile,
    dailyTasks,
    satisfactionSurvey
  },
  plugins: [vuexLocalStorage.plugin, logrocketPlugin],
  strict: process.env.DEV,
})
