import constants from './constants'

export default {
  getNotificationVariantFromId(variantId) {
    switch (variantId) {
      case constants.notificationVariant.Success:
        return 'success'
      case constants.notificationVariant.Warning:
        return 'warning'
      case constants.notificationVariant.Error:
        return 'danger'
      default:
        return 'info'
    }
  },
  getTicketStatusVariantFromId(statusId) {
    switch (statusId) {
      case constants.ticketStatus.Resolved:
        return 'warning'
      default:
        return 'success'
    }
  },
  getTicketStatusNameFromId(contextI18n, statusId) {
    switch (statusId) {
      case constants.ticketStatus.Resolved:
        return contextI18n.$t('tickets.status.resolved')
      default:
        return contextI18n.$t('tickets.status.open')
    }
  },
  getTicketPriorityNameFromId(contextI18n, priorityId) {
    switch (priorityId) {
      case 1:
        return contextI18n.$t('tickets.priority.low')
      case 2:
        return contextI18n.$t('tickets.priority.normal')
      case 3:
        return contextI18n.$t('tickets.priority.high')
      default:
        return contextI18n.$t('tickets.priority.normal')
    }
  },
  getTicketPriorityVariantFromId(priorityId) {
    switch (priorityId) {
      case 1:
        return 'info'
      case 2:
        return 'warning'
      case 3:
        return 'danger'
      default:
        return 'warning'
    }
  },
  getTicketPriorityIconFromId(priorityId) {
    switch (priorityId) {
      case 1:
        return 'ChevronDownIcon'
      case 2:
        return 'MinusIcon'
      case 3:
        return 'ChevronUpIcon'
      default:
        return 'MinusIcon'
    }
  },
  getTicketCategoryNameFromId(contextI18n, categoryId) {
    switch (categoryId) {
      case 1:
        return contextI18n.$t('tickets.category.database')
      case 2:
        return contextI18n.$t('tickets.category.technology')
      case 3:
        return contextI18n.$t('tickets.category.administration')
      case 4:
        return contextI18n.$t('tickets.category.production')
      case 5:
        return contextI18n.$t('tickets.category.satisfaction')
      default:
        return contextI18n.$t('tickets.category.none')
    }
  },
  getTicketCategoryVariantFromId(categoryId) {
    switch (categoryId) {
      case 1:
        return 'primary'
      case 2:
        return 'success'
      case 3:
        return 'danger'
      case 4:
        return 'info'
      case 5:
        return 'warning'
      default:
        return 'secondary'
    }
  },
  getTicketCategoryIconFromId(categoryId) {
    switch (categoryId) {
      case 1:
        return 'DatabaseIcon'
      case 2:
        return 'CpuIcon'
      case 3:
        return 'ArchiveIcon'
      case 4:
        return 'TruckIcon'
      case 5:
        return 'ActivityIcon'
      default:
        return ''
    }
  },
  getWatchLevelIconFromId(watchLevelId) {
    switch (watchLevelId) {
      case 1:
        return 'MenuIcon'
      case 2:
        return 'EyeIcon'
      default:
        return 'EyeOffIcon'
    }
  },
  getProjectStatusVariantFromId(statusId) {
    switch (statusId) {
      case constants.projectStatus.Completed:
        return 'success'
      default:
        return 'warning'
    }
  },
  getProjectStatusNameFromId(contextI18n, statusId) {
    switch (statusId) {
      case constants.projectStatus.Completed:
        return contextI18n.$t('projects.status.completed')
      default:
        return contextI18n.$t('projects.status.open')
    }
  },
}
